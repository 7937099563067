<template>
  <div>
    <div id="dvGrid">
      <dx-data-grid
        ref="gdListaVendas"
        class="dx-card wide-card"
        :data-source="dataVenda"
        :focused-row-index="0"
        :show-borders="false"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :context-menu-enabled="true"
        key-expr="id"
        @cell-prepared="onCellPrepared"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <dx-paging :page-size="50" />
        <dx-pager :show-page-size-selector="true" :show-info="true" />

        <dx-column type="buttons">
          <dx-button
            :visible="verPago"
            icon="money"
            type="normal"
            text="Abrir página de pagamento"
            :on-click="AbrePagamento"
          />
        </dx-column>

        <dx-column data-field="id" caption="Id" />
        <dx-column data-field="NomeSistema" caption="Sistema" />
        <dx-column data-field="NomePlano" caption="Plano" />
        <dx-column data-field="valorpago" caption="Valor" />
        <dx-column
          data-field="datavenda"
          caption="Data venda"
          data-type="date"
          format="dd/MM/yyyy HH:mm:ss"
        />
        <dx-column
          data-field="datapagamento"
          caption="Data pagamento"
          data-type="date"
          format="dd/MM/yyyy HH:mm:ss"
        />
        <dx-column
          data-field="pago"
          caption="Pago"
          cell-template="statusTemplate"
        />

        <template #statusTemplate="{ data }">
          <va-chip
            v-if="data.value == '0' && data.data.status == 'criada'"
            class="mb-2 mr-2"
            color="warning"
            >Aguardando</va-chip
          >
          <va-chip
            v-if="data.value == '0' && data.data.status != 'criada'"
            class="mb-2 mr-2"
            color="danger"
            >NÃO # {{ data.data.status }}</va-chip
          >
          <va-chip v-if="data.value == '1'" class="mb-2 mr-2" color="success"
            >SIM</va-chip
          >
        </template>
      </dx-data-grid>
    </div>

    <DxLoadPanel
      :position="{ of: '#dvListaVendas' }"
      v-model:visible="loadingVisible"
      :shading="true"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>

<script>
import conserv from "@/api/conserv";

import { DxLoadPanel } from "devextreme-vue/load-panel";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxExport,
  DxButton,
} from "devextreme-vue/data-grid";

export default {
  name: "licencas",
  mixins: [conserv],
  components: {
    DxLoadPanel,
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxExport,
    DxButton,
  },
  data() {
    return {
      loadingVisible: false,
      dataVenda: null,
      regEdicao: {},
      atvPopEdita: false,
      atvPopRenova: false,
    };
  },
  methods: {
    verPago(e) {
      if (e.row.data.pago == "1") return false;
      else return true;
    },
    AbrePagamento(e) {
      var reg = e.row.data;
      window.open(reg.urlpag, "_blank").focus();
    },
    onCellPrepared(e) {
      if (e.column.dataField === "valorpago") {
        const tpFormatValor = {
          style: "currency",
          currency: "BRL",
          useGrouping: true,
          precision: 2,
        };
        e.column.format = tpFormatValor;
      }
    },
    async ListaRegistros() {
      this.loadingVisible = true;
      let regs = await this.connServ("api/Vendas/ListaVendas", "get", "");
      console.log(JSON.stringify(regs));
      this.dataVenda = [...regs.dados.registro];
      this.loadingVisible = false;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        options: {
          icon: "refresh",
          text: "Atualizar",
          elementAttr: {
            id: "btAtualiza",
          },
          onClick: this.ListaRegistros.bind(this),
        },
      });
    },
  },
  mounted() {
    this.ListaRegistros();
  },
};
</script>

<style>
.cssDemo {
  color: chocolate;
}

.cssReal {
  color: green;
}
</style>